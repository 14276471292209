<template>
  <div id="app-layout">           
   <div class="home__title">
              <h2 class="home__title__bg">NOS MISSIONS</h2>
            </div>         
            <div class="pagecontent">
              <div class="missions__vid__container">
                <iframe class="missions__vid" width="560" height="315" src="https://www.youtube.com/embed/4Tm5u-fIHU8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="missions__text">
                  <p class="missions__title">Le notaire est un juriste investi d’une mission d’autorité publique qui prépare des contrats sous la forme authentique pour le compte de ses clients. Il exerce ses fonctions dans un cadre libéral.</p>
                  <li class="missions__point">Un Officier Public</li>
                  <p>Le notaire est un officier public, nommé par le Ministre de la Justice, que l’Etat charge d’une mission de service public. Pour l’exécution de sa mission, l’Etat lui délègue une parcelle de l’autorité publique : il assure le service public de l’authenticité. Cela signifie qu’il possède de véritables prérogatives de puissance publique, qu’il reçoit de l’Etat.</p>
                  <li class="missions__point">Un professionnel de l'authentification des actes</li>
                  <p>Le notaire a le pouvoir d’authentifier les actes en apposant son sceau et sa propre signature. Il constate ainsi officiellement la volonté exprimée par les personnes qui les signent et s’engage personnellement sur le contenu et sur la date de l’acte. Cet acte s’impose alors avec la même force qu’un jugement définitif. A ce titre, le notaire est le magistrat de l’amiable, acteur d’une justice amiable.</p>
                  <p>Le notariat doit assurer la consultation et la conservation des actes pendant 75 ans (100 ans pour les actes qui concernent un mineur), après quoi les documents seront versés aux archives.</p>
                  <p>Pour certains actes, le recours à un notaire est obligatoire comme en matière de contrat de mariage, donation entre époux, donation-partage, et pour les besoins de la publicité foncière, la donation ou la vente d'un bien immobilier (appartement, maison, terrain).</p>
                  <li class="missions__point">Un professionnel libéral</li>
                  <p>Bien qu’investi de l’autorité publique, le notaire exerce ses fonctions dans un cadre libéral, assurant ainsi une forme moderne de service public sans coût pour l’Etat, puisqu’il assume la responsabilité économique de son étude. C’est un professionnel libéral, rémunéré par ses clients (et non par les contribuables) selon un tarif fixé par l’Etat pour les services qu’il rend.</p>
                  <li class="missions__point">Un professionnel présent sur tout le territoire</li>
                  <p>L'implantation des notaires fait l’objet d’une adaptation permanente sous le contrôle de la chancellerie, afin d’assurer une bonne couverture du territoire. Elle obéit à trois principes : maintenir un service public juridique de proximité, tenir compte des évolutions géographiques et démographiques veiller aux conditions économiques d’exercice de la profession afin d’assurer un service de qualité.</p>
                  <a class="missions__lien" href="https://www.notaires.fr/fr/profession-notaire/r%C3%B4le-du-notaire-et-ses-principaux-domaines-dintervention/le-r%C3%B4le-du-notaire">Pour en savoir plus : <span class="missions__lien__color">notaires.fr</span></a>
              </div>
            </div>
  </div>
</template>

<script>
  export default {
      metaInfo: {
    // Children can override the title.
    title: 'Missions - Office Notarial de Me Montagner',
    link: [
    {rel: 'canonical', href: 'https://angelique-montagner.notaires.fr/Missions'}
   ]
  },
    name: "missions"
  }
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;


.missions {
    &__title {
        font-weight: bolder;
        font-size: larger;
    }
    &__vid {
        margin: auto;
        display: block;
        @media screen and (max-width: 610px){
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
             }
        &__container {
            padding: 0;
            width: 560px;
            margin: auto;
            height: 315px;
            @media screen and (max-width: 610px){
                position: relative;
                overflow: hidden;
                width: 100%;
                padding-top: 56.25%;
                height: auto;
                }     
             }
        }
    &__text {
        width: 80%;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: justify;
        @media screen and (max-width: 822px){
            width: 100%;
            } 
         }
    &__point {
        padding-left: 50px;
        font-size: larger;
        font-weight: 700;
        color: $tertiary-color;
        margin-top: 15px;
        }
    &__lien {
        text-align: right;
        &__color {
            color: $tertiary-color;
        }
    }
} 

@keyframes service {
    0% {
        transform: translateY(10px);
        opacity: 0;
  }
      
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@for $i from 1 through 9 {
    .links__presta__block:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.25}s; }
 }

.links__presta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0;
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-top: 20px;
    @media screen and (max-width: 610px){
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
         }
    &__block {
            text-align: center;
            margin: 0 10px;
            width: 25%;
            animation: service 1000ms;
            animation-fill-mode: backwards;
            @media screen and (max-width: 610px){
                margin: 20px 10px;
                width: fit-content;
                 }
        }
}

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}


.content {
    width: 75%;
    margin: auto;
    padding-bottom: 10px;
    background-color: rgba(250,250,250,0.9);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

.home {
    display: block;
    &__title {        
        font-family: 'Quicksand', sans-serif;
        margin: 0;
        text-align: center;
        box-sizing: border-box;
        padding-top: 150px;
        padding-bottom: 150px;
        color: black;
        text-shadow: 0 0 1px $tertiary-color;
        &__bg {
            background-color: rgba(229, 172, 95, 0.8);
            margin: auto;
            box-sizing: border-box;
            padding: 15px;
            border-radius: 4px;
            width: fit-content;
        }
    }
}


.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
    @media screen and (max-width: 549px){
        flex-direction: column;
        padding: 0 20px;
        box-sizing: border-box;
         }
    &__list {
    list-style-type: none;
    @media screen and (max-width: 720px){
        padding: 0;
        width: fit-content;
        margin: auto;
         }
    }
    &__link {
        display: flex;
        margin-bottom: 15px;
        text-decoration: none;
        color: black;
        flex-direction: row;
        align-items: center;
        &__text {
            margin: 0;
            text-decoration: none;
            color: black;
            @media screen and (max-width: 399px){
                white-space: nowrap;
                font-size: smaller;
                 }
            &:hover {
                text-decoration: none;
                color: black;
            }
            &:visited {
                text-decoration: none; 
                color: black;
                 }
            }    
        }
    &__icon {
            margin-right: 10px;
        }
    &__findus {
        @media screen and (max-width: 399px){
            overflow: hidden;
             }
    }
}

.bienvenue {
    display: flex;
    align-items: center;
    @media screen and (max-width: 699px){
        flex-direction: column;
         }
    &__img {
        width: 50%;
        margin: 20px;
        border-radius: 5px;
        @media screen and (max-width: 699px){
            width: 95%;
            margin-top: 10px;
             }
    }
    &__text{
        text-align: center;
        margin: auto;
    }
    &__word {
        color: $tertiary-color;
    }
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    padding: 15px 0;
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-top: 20px;
    @media screen and (max-width: 450px){
        flex-direction: column;
         }
    &__icon {
        font-size: 80px;
        color: $tertiary-color;
        margin-bottom: 20px;
    }
    &__block {
        text-align: center;
        margin: 0 10px;
        @media screen and (max-width: 450px){
            margin: 20px 10px;
             }
    }
    &__text {
    border: 0.5px solid;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0.5px 0.5px 0px 0px $tertiary-color;
    transition: all 250ms;
    &:hover {
        background-color: lighten($color: $tertiary-color, $amount: 15);
    }
    }
}
</style>